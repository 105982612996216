import React from "react";
import Body from "../../layout/body";
import Profile from "../../component/profile";
import { Query } from "react-contentful";
import { Link } from "react-router-dom";
import { months, defaultTitle, defaultImage, defaultSubTitle } from "../../utility";
import Helmet from "react-helmet";

const Home = () => {
  return (
    <Body>
      <div id="main" className="site-main">
        <div className="layout-medium">
          <div id="primary" className="content-area with-sidebar">
            <div id="content" className="site-content" role="main">
              <header className="entry-header">
                <h1 className="entry-title">
                  <i>Page</i> <span className="cat-title">Home</span>
                </h1>
              </header>

              <Query contentType={"story"} query={{ order: "-fields.createdTime" }}>
                {({ data, error, fetched, loading }) => {
                  if (loading || !fetched) {
                    return <p>Loading...</p>;
                  }

                  if (error) {
                    console.error(error);
                    return null;
                  }

                  if (!data) {
                    return <p>Page does not exist.</p>;
                  }
                  var link = "post-detail";
                  const firstItem = data.items[0].fields;
                  const date = new Date(firstItem.createdTime);
                  const parseDate = `${date.getDate()} ${months[date.getMonth()]
                    } ${date.getFullYear()}`;
                  var arr = data.items.slice(1)
                  return (
                    <>
                      <Helmet>
                        <title>{defaultTitle}</title>
                        <meta name="description" content={defaultSubTitle} />
                        <meta name="og:image" content={defaultImage} />
                      </Helmet>
                      <article className="hentry has-post-thumbnail" >
                        <div className="post-thumbnail"
                          style={{ backgroundImage: `url(${data.items[0].fields.heroImage.fields.file.url})` }}>
                          <header className="entry-header">
                            <div className="entry-meta">
                              <span className="cat-links">
                                <a href="#" title="View all posts in Life" rel="category tag">
                                  {data.items[0].fields.category.fields.namaKategori}
                                </a>
                              </span>
                            </div>
                            <h2 className="entry-title"><a href={`/${link}?slug=${data.items[0].fields.slug}`}>{data.items[0].fields.title}</a></h2>
                            <div className="entry-content" style={{ color: 'white' }}>
                              <p>
                                {data.items[0].fields.subtitle}
                              </p>
                            </div>
                            <Link
                              to={`/${link}?slug=${data.items[0].fields.slug}`}
                              title={data.items[0].fields.title}
                              className="more-link"
                            >
                              Read More
                          </Link>
                          </header>
                        </div>
                      </article>
                      <div className="blog-list blog-stream">
                        {arr.map((item, idx) => {
                          const date = new Date(item.fields.createdTime);
                          const parseDate = `${date.getDate()} ${months[date.getMonth()]
                            } ${date.getFullYear()}`;
                          return (
                            <article key={idx} className="hentry post has-post-thumbnail">
                              <div className="featured-image">
                                <Link
                                  to={`/${link}?slug=${item.fields.slug}`}
                                  title={item.fields.title}
                                >
                                  <img src={item.fields.heroImage.fields.file.url} />
                                </Link>
                              </div>
                              <div className="hentry-middle">
                                <header className="entry-header">
                                  <div className="entry-meta">
                                    <span className="cat-links">
                                      <a href="#" rel="category tag">
                                        {item.fields.category.fields.namaKategori}
                                      </a>
                                    </span>
                                    <span className="entry-date">
                                      <time className="entry-date">{parseDate}</time>
                                    </span>
                                  </div>
                                  <h2 className="entry-title">
                                    <Link
                                      to={`/${link}?slug=${item.fields.slug}`}
                                      title={item.fields.title}
                                    >
                                      {item.fields.title}
                                    </Link>
                                  </h2>
                                </header>
                                <div className="entry-content">
                                  <p>
                                    {item.fields.subtitle}
                          &nbsp;
                          <span className="more">
                                      <Link
                                        to={`/${link}?slug=${item.fields.slug}`}
                                        title={item.fields.title}
                                        className="more-link"
                                      >
                                        Read More
                            </Link>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </article>
                          );
                        })}
                      </div>
                    </>
                  );
                }}

              </Query>
            </div>
          </div>
          <Profile />
        </div>
      </div>
    </Body >
  );
};

export default Home;
