const months = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Junli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];
const defaultTitle = "Joy Gabriel Official Site";
const defaultSubTitle = "Stories, Talks, and Works";
const defaultImage = "https://joygabriel.site/images/site/about-me.jpg"

export { months, defaultTitle, defaultSubTitle, defaultImage };
