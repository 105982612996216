import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Query } from "react-contentful";
import { months, defaultTitle } from "../../utility";
import Helmet from "react-helmet";

const PostList = ({ post }) => {
  return (
    <Query contentType={post} query={{ order: "-fields.createdTime" }}>
      {({ data, error, fetched, loading }) => {
        if (loading || !fetched) {
          return <p>Loading...</p>;
        }

        if (error) {
          console.error(error);
          return null;
        }

        if (!data) {
          return <p>Page does not exist.</p>;
        }
        var link = "post-detail";
        if (post === "media") link = "media-detail";
        // Process and pass in the loaded `data` necessary for your page or child components.
        return (
          <>
            <Helmet>
              <title>{defaultTitle}</title>
            </Helmet>
            <div className="blog-list blog-stream">
              {data.items.map((item, idx) => {
                const date = new Date(item.fields.createdTime);
                const parseDate = `${date.getDate()} ${
                  months[date.getMonth()]
                } ${date.getFullYear()}`;
                return (
                  <article key={idx} className="hentry post has-post-thumbnail">
                    <div className="featured-image">
                      <Link
                        to={`/${link}?slug=${item.fields.slug}`}
                        title={item.fields.title}
                      >
                        <img src={item.fields.heroImage.fields.file.url} />
                      </Link>
                    </div>
                    <div className="hentry-middle">
                      <header className="entry-header">
                        <div className="entry-meta">
                          <span className="cat-links">
                            <a href="#" rel="category tag">
                              {item.fields.category.fields.namaKategori}
                            </a>
                          </span>
                          <span className="entry-date">
                            <time className="entry-date">{parseDate}</time>
                          </span>
                        </div>
                        <h2 className="entry-title">
                          <Link
                            to={`/${link}?slug=${item.fields.slug}`}
                            title={item.fields.title}
                          >
                            {item.fields.title}
                          </Link>
                        </h2>
                      </header>
                      <div className="entry-content">
                        <p>
                          {item.fields.subtitle}
                          &nbsp;
                          <span className="more">
                            <Link
                              to={`/${link}?slug=${item.fields.slug}`}
                              title={item.fields.title}
                              className="more-link"
                            >
                              Read More
                            </Link>
                          </span>
                        </p>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </>
        );
      }}
    </Query>
  );
};

PostList.propTypes = {
  children: PropTypes.string,
};

export default PostList;
