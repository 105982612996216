import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ContentfulClient, ContentfulProvider } from "react-contentful";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

// Pages
import Home from "../src/pages/home";
import PostDetail from "./pages/post-detail";
import Talks from "./pages/talks";
import About from "./pages/about";
import Media from "./pages/media";
import MediaDetail from "./pages/media-detail";

// Initialize google analytics page view tracking
ReactGA.initialize("UA-131792517-5");
const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const contentfulClient = new ContentfulClient({
  accessToken: "MJ4b8wmIDXmL7Vyoc1AjEERxVrG0gVbdBf4uteyDadc",
  space: "0n6jc57u3jbb",
});

const RootApp = () => {
  return (
    <BrowserRouter>
      <ContentfulProvider client={contentfulClient}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/post-detail/:slug*" component={PostDetail} />
          <Route path="/media-detail/:slug*" component={MediaDetail} />
          <Route path="/talks" component={Talks} />
          <Route path="/media" component={Media} />
          <Route path="/about" component={About} />
          {/* Misc */}
          {/* <Route component={Error404} /> */}
        </Switch>
      </ContentfulProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(<RootApp />, document.getElementById("root"));
