import React from "react";
import Body from "../../layout/body";
import Helmet from "react-helmet";
import Profile from "../../component/profile";
import PostList from "../../component/post-list";
import { defaultImage } from "../../utility";

const Media = () => {
  return (
    <Body>
      <Helmet>
        <title>Joy Gabriel : On The Media</title>
        <meta name="description" content="Joy Gabriel On the Media" />
        <meta name="og:image" content={defaultImage} />
      </Helmet>
      <div id="main" className="site-main">
        <div className="layout-medium">
          <div id="primary" className="content-area with-sidebar">
            <div id="content" className="site-content" role="main">
              <header className="entry-header">
                <h1 className="entry-title">
                  <i>Page</i> <span className="cat-title">On The Media</span>
                </h1>
              </header>
              <PostList post={"media"} />
            </div>
          </div>
          <Profile />
        </div>
      </div>
    </Body>
  );
};

export default Media;
