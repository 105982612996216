import React from "react";
import Body from "../../layout/body";
import { Query } from "react-contentful";
import RichText from "@madebyconnor/rich-text-to-jsx";
import Helmet from "react-helmet";

const About = () => {
  return (
    <Body>
      <Helmet>
        <title>Joy Gabriel : About Me</title>
      </Helmet>
      <div id="main" className="site-main">
        <div className="layout-medium">
          <div id="content" class="site-content" role="main">
            <header className="entry-header">
              <h1 className="entry-title">
                <i>Page</i> <span className="cat-title">About Me</span>
              </h1>
            </header>
            <Query contentType="about">
              {({ data, error, fetched, loading }) => {
                if (loading || !fetched) {
                  return <p>Loading...</p>;
                }

                if (error) {
                  console.error(error);
                  return null;
                }

                if (!data) {
                  return <p>Page does not exist.</p>;
                }
                const item = data.items[0].fields;
                // Process and pass in the loaded `data` necessary for your page or child components.
                return (
                  <div className="blog-list blog-stream">
                    <aside class="about-author">
                      <div class="author-bio">
                        <div class="author-img">
                          <a href="#">
                            <img
                              alt="Johnny Doe"
                              src={item.image.fields.file.url}
                              class="avatar"
                            />
                          </a>
                        </div>
                        <div class="author-info">
                          <h4 class="author-name">Joy Gabriel</h4>
                          <RichText richText={item.detailProfil} />
                          <p>
                            <a
                              href="https://linkedin.com/in/joygabriel"
                              class="button"
                            >
                              <i class="pw-icon-linkedin-squared"></i>View My
                              LinkedIn Profile
                            </a>
                          </p>
                        </div>
                      </div>
                    </aside>
                  </div>
                );
              }}
            </Query>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default About;
