import React from "react";
import Body from "../../layout/body";
import Profile from "../../component/profile";
import PostBody from "../../component/post-body";

const PostDetail = (props, title) => {
  const url_param = new URLSearchParams(props.location.search);

  return (
    <Body>
      <div id="main" className="site-main">
        <div className="layout-medium">
          <div id="primary" className="content-area with-sidebar">
            <PostBody param={url_param} title={title} topic={"story"} />
          </div>
          <Profile />
        </div>
      </div>
    </Body>
  );
};

export default PostDetail;
