import React from "react";

const Footer = () => {
  return (
    <footer id="colophon" className="site-footer" role="contentinfo">
      <div className="layout-medium">
        <div className="footer-social">
          <div className="textwidget">
            <a
              className="social-link facebook"
              href="https://fb.me/joygeneroso"
            ></a>
            <a
              className="social-link linkedin"
              href="https://linkedin.com/in/joygabriel"
            ></a>
            <a
              className="social-link instagram"
              href="https://instagram.com/joygabriel.18"
            ></a>
          </div>
        </div>
      </div>
      <div className="site-info">
        <div className="layout-medium">
          <div className="textwidget">
            {new Date().getFullYear()} © Joy Gabriel.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
