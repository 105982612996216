import React from "react";
import Header from "./header";
import Footer from "./footer";
import PropTypes from "prop-types";

const Body = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

Body.propTypes = {
  children: PropTypes.node,
};

export default Body;
