import React from "react";

const Profile = () => {
  return (
    <div id="secondary" className="widget-area sidebar" role="complementary">
      <aside className="widget widget_text">
        <h3 className="widget-title">About Me</h3>
        <div className="textwidget">
          <p>
            <img src="images/site/about-me.jpg" alt="avatar" />
          </p>
          <b>Joy Gabriel</b>
          <p>
            Data Innovator and Consultant | 3x Alibaba Cloud MVP (2019, 2020,
            2021)
          </p>
        </div>
      </aside>
      <aside className="widget widget_text">
        <h3 className="widget-title">Follow Me</h3>
        <div className="textwidget">
          <p>
            <a
              className="social-link facebook"
              href="https://fb.me/joygeneroso"
            ></a>
            <a
              className="social-link linkedin"
              href="https://linkedin.com/in/joygabriel"
            ></a>
            <a
              className="social-link instagram"
              href="https://instagram.com/joy.gabriel18"
            ></a>
          </p>
        </div>
      </aside>
    </div>
  );
};

export default Profile;
