import React from "react";
import Body from "../../layout/body";
import { Query } from "react-contentful";
import RichText from "@madebyconnor/rich-text-to-jsx";
import Helmet from "react-helmet";

const Talks = () => {
  return (
    <Body>
      <Helmet>
        <title>Joy Gabriel : Talks</title>
      </Helmet>
      <div id="main" className="site-main">
        <div className="layout-medium">
          <div id="content" class="site-content" role="main">
            <header className="entry-header">
              <h1 className="entry-title">
                <i>Page</i> <span className="cat-title">Talks</span>
              </h1>
            </header>
            <Query contentType="talks">
              {({ data, error, fetched, loading }) => {
                if (loading || !fetched) {
                  return <p>Loading...</p>;
                }

                if (error) {
                  console.error(error);
                  return null;
                }

                if (!data) {
                  return <p>Page does not exist.</p>;
                }
                const item = data.items[0].fields;
                // Process and pass in the loaded `data` necessary for your page or child components.
                return (
                  <article class="hentry page">
                    <div id="content" class="site-content" role="main">
                      <h4>Preface</h4>
                      <p>
                        <RichText richText={item.preface} />
                      </p>
                      <h4>List of All Talks</h4>
                      <div class="row">
                        <div class="col-sm-12">
                          <p>
                            <RichText richText={item.listOfTalks} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                );
              }}
            </Query>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default Talks;
