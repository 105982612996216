import React from "react";
import { Query } from "react-contentful";
import { months, defaultTitle, defaultSubTitle } from "../../utility";
import RichText from "@madebyconnor/rich-text-to-jsx";
import Helmet from "react-helmet";

const PostBody = ({ param, topic, title }) => {
  return (
    <Query
      contentType={topic}
      query={{
        "fields.slug": `${param.get("slug") || ""}`,
      }}
    >
      {({ data, error, fetched, loading }) => {
        if (loading || !fetched) {
          return <p>Loading...</p>;
        }

        if (error) {
          console.error(error);
          return null;
        }

        if (!data) {
          return <p>Page does not exist.</p>;
        }
        const item = data.items[0].fields;
        const date = new Date(item.createdTime);
        const parseDate = `${date.getDate()} ${months[date.getMonth()]
          } ${date.getFullYear()}`;

        // Process and pass in the loaded `data` necessary for your page or child components.
        return (
          <>
            <Helmet>
              <title>{title ? `${item.title} - Joy Gabriel` : defaultTitle}</title>
              <meta name="description" content={item.subtitle ? `${item.subtitle} - Joy Gabriel` : defaultSubTitle} />
              <meta name="og:image" content={item.heroImage.fields.file.url} />
            </Helmet>
            <div id="content" className="site-content" role="main">
              <article className="hentry post single-post">
                <header className="entry-header">
                  <h1 className="entry-title">{item.title}</h1>

                  <div className="entry-meta">
                    <span class="cat-links">
                      <a href="/#" target="_blank" rel="noreferrer">
                        {item.category.fields.namaKategori}
                      </a>
                    </span>
                    <span className="entry-date">
                      <time className="entry-date">{parseDate}</time>
                    </span>
                  </div>
                </header>
                <div className="featured-image">
                  <img src={item.heroImage.fields.file.url} alt="hero" />
                </div>
                <div className="entry-content">
                  <RichText richText={item.body} />

                  <div className="share-links">
                    <h3>SHARE THIS POST</h3>
                    {/* <a
                              target="_blank"
                              rel="noreferrer"
                              href={`http://twitter.com/home?status=${window.location.href}`}
                            >
                              <i className="pw-icon-twitter"></i>
                            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href={`http://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${item.title}`}
            >
              <i className="pw-icon-linkedin-squared"></i>
            </a> */}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      data-action="share/whatsapp/share"
                      href={`https://web.whatsapp.com/send?text=${window.location.href}`}
                    >
                      <i className="pw-icon-whatsapp"></i>
                    </a>
                  </div>
                </div>
              </article >
            </div >
          </>
        );
      }}
    </Query >
  );
};

export default PostBody;
