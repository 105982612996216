import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header id="masthead" className="site-header" role="banner">
      <nav
        id="primary-navigation"
        className="site-navigation primary-navigation"
        role="navigation"
      >
        <div className="layout-medium">
          <h1 className="site-title">
            <a href="/" rel="home">
              <span className="screen-reader-text">Joy Gabriel</span>
            </a>
          </h1>
          <h1 className="site-title">
            <a href="/" rel="home">
              Joy Gabriel
            </a>
          </h1>

          <a className="menu-toggle">
            <span className="lines"></span>
          </a>

          <div className="nav-menu">
            <ul>
              <li>
                <Link to="/">HOME</Link>
              </li>

              <li>
                <Link to="/media">ON THE MEDIA</Link>
              </li>
              <li>
                <Link to="/talks">TALKS</Link>
              </li>
              <li>
                <Link to="/about">ABOUT ME</Link>
              </li>
            </ul>
          </div>
          {/* <a className="search-toggle toggle-link"></a>
          <div className="search-container">
            <div className="search-box" role="search">
              <form method="get" className="search-form" action="#">
                <label>
                  Search Here
                  <input
                    type="search"
                    id="search-field"
                    placeholder="type and hit enter"
                    name="s"
                  />
                </label>
                <input type="submit" className="search-submit" value="Search" />
              </form>
            </div>
          </div> */}

          <div className="social-container">
            <a
              className="social-link facebook"
              href="https://fb.me/joygeneroso"
            ></a>
            <a
              className="social-link linkedin"
              href="https://linkedin.com/in/joygabriel"
            ></a>
            <a
              className="social-link instagram"
              href="https://instagram.com/joygabriel.18"
            ></a>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
